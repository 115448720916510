<template>
  <div>
    <h3 class="font-weight-bolder mb-1">
      {{ $t("Available position") }}
    </h3>
    <validation-observer ref="simpleRules">
      <div class="w-100 px-1 py-2 create-form-body m-0 d-flex">
        <div class="col-lg-8 px-0">
          <div class="d-md-flex align-items-center mb-1">
            <h5 class="font-weight-bolder w-25 mb-md-0">
              {{ $t("Job type") }}
            </h5>
            <b-form-group class="mb-0 w-75">
              <validation-provider
                #default="{ errors }"
                :name="$t('Job type')"
                rules="required"
              >
                <b-form-radio-group
                  v-model="job.type"
                  :options="job_types"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </div>
          <div class="d-md-flex align-items-center mb-1">
            <h5 class="font-weight-bolder w-25 mb-md-0">
              {{ $t("Category") }}
            </h5>
            <validation-provider
              #default="{ errors }"
              :name="$t('Category')"
              rules="required"
            >
              <b-form-checkbox-group
                v-model="job.job_categories"
                :options="jobCategories"
                value-field="id"
                text-field="name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="row">
            <div class="col-md-4 px-md-1 pl-1">
              <b-form-group
                :label="$t('Position type')"
                label-for="positionType"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Position type')"
                  rules="required"
                >
                  <v-select
                    id="positionType"
                    v-model="job.rate"
                    class="bg-light"
                    label="text"
                    :options="rates"
                    :reduce="(rate) => rate.value"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div class="col-md-8 px-md-1 pl-1">
              <b-form-group
                :label="$t('Specialty')"
                label-for="specialty"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Specialty')"
                  rules="required"
                >
                  <v-select
                    id="specialty"
                    v-model="job.specialties"
                    class="bg-light"
                    label="name"
                    multiple
                    :reduce="(specialty) => specialty.id"
                    :options="specialties"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <b-form-group :label="$t('Job title')">
            <validation-provider
              #default="{ errors }"
              :name="$t('Job title')"
              rules="required|max:80"
            >
              <b-form-input
                v-model="job.title"
                class="bg-light"
                :state="errors.length > 0 ? false : null"
                :placeholder="$t('Place some text there...')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Description')"
            label-for="tinymce-editor"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Description')"
              rules="required"
            >
              <TinyMCE
                id="tinymce-editor"
                v-model="job.description"
                :placeholder="$t('Place some text there...')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="d-md-flex align-items-center mb-1">
            <h5 class="font-weight-bolder w-25 mb-md-0">
              {{ $t("Hiring date") }}
            </h5>
            <b-form-group class="mb-0">
              <b-form-radio-group
                v-model="hiring_date"
                :options="hiring_dates"
              />
            </b-form-group>
          </div>
          <div
            class="row mb-2"
          >
            <div class="col-md-4 px-md-1 pl-1">
              <b-form-group
                :label="$t('Application deadline')"
                label-for="applicationDeadline"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Application deadline')"
                  rules="required"
                >
                  <b-form-datepicker
                    id="applicationDeadline"
                    v-model="job.application_deadline"
                    nav-button-variant="muted"
                    class="bg-light"
                    placeholder="YYYY/MM/DD"
                    :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                    :date-format-options="{
                      year: 'numeric',
                      day: 'numeric',
                      month: 'numeric',
                    }"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div
              v-if="hiring_date"
              class="col-md-4 px-md-1 pl-1"
            >
              <b-form-group
                :label="$t('Select hiring date')"
                label-for="selectHiringDate"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Select hiring date')"
                  rules="required"
                >
                  <b-form-datepicker
                    id="selectHiringDate"
                    v-model="job.hiring_date"
                    nav-button-variant="muted"
                    class="bg-light"
                    placeholder="YYYY/MM/DD"
                    :locale="$i18n.locale === 'en' ? 'en-UK' : 'nb-NO'"
                    :date-format-options="{
                      year: 'numeric',
                      day: 'numeric',
                      month: 'numeric',
                    }"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <h5
            v-if="employer.workplace_situation_group"
            class="font-weight-bolder mb-1"
          >
            {{ $t("Employer") }}
          </h5>
          <ChooseWorkplaceForm
            v-if="employer.workplace_situation_group"
            :value="employer"
            :have-initial-data="$route.params.id ? true : false"
            :only-hospital="true"
            class="mb-2"
            @changeCounty="selectCounty"
          />
          <h5 class="font-weight-bolder">
            {{ $t("Work address") }}
          </h5>
          <b-form-group
            :label="$t('Address line')"
            label-for="addressLine1"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Address line')"
              rules="required"
            >
              <b-form-input
                id="addressLine1"
                v-model="job.work_address_1"
                class="bg-light"
                :placeholder="$t('Address line')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div
            class="row"
            :class="employer.workplace_situation_group === 'general_practice'? 'mb-2' : ''"
          >
            <div class="col-md-6 px-md-1 pl-1">
              <b-form-group
                :label="$t('Postal code')"
                label-for="postalCode"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Postal code')"
                  rules="required|min:2"
                >
                  <cleave
                    v-model="job.postal_code"
                    class="form-control bg-light"
                    :raw="false"
                    :options="options.postal_code"
                    :placeholder="$t('Postal code')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <div
              class="col-md-6 px-md-1 pl-1"
            >
              <b-form-group
                :label="$t('Area')"
                label-for="Area"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('Area')"
                  rules="required"
                >
                  <b-form-input
                    id="Area"
                    v-model="job.work_address_2"
                    class="bg-light"
                    :placeholder="$t('Area')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="employer.workplace_situation_group !== 'general_practice'"
            class="row mb-2"
          >
            <div
              class="col-md-6 px-md-1 pl-1"
            >
              <b-form-group
                :label="$t('County')"
                label-for="city"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('County')"
                  rules="required"
                >
                  <v-select
                    v-model="job.county_id"
                    class="bg-light"
                    :options="counties"
                    label="name"
                    :reduce="(county) => county.id.toString()"
                    :placeholder="$t('County')"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>

          </div>
          <h5 class="font-weight-bolder">
            {{ $t("Contacts") }}
          </h5>
          <b-form-group
            :label="$t('Contact person')"
            label-for="contactPerson"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Contact person')"
              rules="required"
            >
              <b-form-input
                id="contactPerson"
                v-model="job.contact_person"
                class="bg-light"
                :placeholder="$t('Contact person')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Phone')"
            label-for="contactPhoneNumber"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Phone')"
              rules="required|phone"
            >
              <cleave
                v-model="job.contact_phone_number"
                class="form-control bg-light"
                :raw="false"
                :options="options.phone"
                :placeholder="$t('Phone')"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            :label="$t('Link to recruiting system')"
            label-for="linkToRecruitingSystem"
          >
            <validation-provider
              #default="{ errors }"
              :name="$t('Link to recruiting system')"
              rules="url"
            >
              <b-form-input
                id="linkToRecruitingSystem"
                v-model="job.recruiting_system_link"
                class="bg-light"
                placeholder="https://"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-checkbox
            v-if="!this.$route.params.id && isABussinesProfile"
            v-model="job.generate_post"
            class="mt-1"
          >
            {{ $t('Generate the post to the feed') }}
          </b-form-checkbox>
        </div>
        <div class="col-lg-4 pl-1 text-right nopadding">
          <b-button
            class="mr-2"
            variant="primary"
            @click="submitAction"
          >
            {{ $t(this.$route.params.id ? 'Save changes' : 'Publish') }}
          </b-button>
          <b-button
            variant="outline-primary"
            @click="closeCreationForm"
          >
            {{ $t(this.$route.params.id ? 'Close vacancy' : 'Cancel') }}
          </b-button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import moment from 'moment/min/moment-with-locales'
import {
  BButton,
  BFormInput,
  BFormGroup,
  BFormDatepicker,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, digits, regex, min, max, email, phone,
} from '@validations'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.no'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import TinyMCE from '@/components/editor/TinyMCE.vue'
import ChooseWorkplaceForm from '@/components/workplace/ChooseWorkplaceForm.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BFormGroup,
    BFormDatepicker,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadioGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    TinyMCE,
    ChooseWorkplaceForm,
  },
  props: {
    mode: {
      type: String,
      default: 'webcast',
    },
  },
  data() {
    return {
      employer: {
        workplace_situation_group: null,
        workplace_situation_type: null,
        workplace_situation_entity_id: null,
      },
      options: {
        phone: {
          blocks: [11],
        },
        postal_code: {
          blocks: [4],
        },
      },
      required,
      url,
      digits,
      regex,
      phone,
      min,
      max,
      email,
      job: {
        rate: '25',
        title: null,
        description: null,
        application_deadline: null,
        work_address_1: null,
        work_address_2: null,
        postal_code: null,
        contact_person: null,
        contact_phone_number: null,
        recruiting_system_link: null,
        county_id: null,
        city: 'none',
        city_id: 1,
        specialties: null,
        type: null,
        job_categories: [],
        workplace_id: 1,
        generate_post: false,
      },
      hiring_date: true,
      rates: [
        {
          text: '25%',
          value: '25',
        },
        {
          text: '50%',
          value: '50',
        },
        {
          text: '75%',
          value: '75',
        },
        {
          text: '100%',
          value: '100',
        },
      ],
      job_types: [
        { text: this.$t('Full time'), value: 'full_time' },
        { text: this.$t('Substitude'), value: 'substitude' },
      ],
      hiring_dates: [
        { text: this.$t('Select date'), value: true },
        { text: this.$t('ASAP'), value: false },
      ],
    }
  },
  computed: {
    isABussinesProfile() {
      return this.$store.getters['auth/user'].active_business_profile.id
    },
    counties() {
      return this.translate(this.$store.getters['filterData/counties'], this.$i18n.locale)
    },
    specialties() {
      return this.translate(this.$store.getters['specialties/specialties'], this.$i18n.locale)
    },
    jobCategories() {
      return this.translate(this.$store.getters['jobCategories/jobCategories'], this.$i18n.locale)
    },
    workplaces() {
      return this.translate(this.$store.getters['workplaces/workplaces'], this.$i18n.locale)
    },
    customWorkplaces() {
      return this.$store.getters['customWorkplaces/workplaces']
    },
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    'employer.workplace_situation_group'() {
      this.$refs.simpleRules.reset()
    },
    // eslint-disable-next-line object-shorthand
  },
  async created() {
    this.$store.dispatch('filterData/fetchCounties')
    this.$store.dispatch('specialties/fetchSpecialties')
    this.$store.dispatch('jobCategories/fetchJobCategories')
    this.$store.dispatch('workplaces/fetchWorkplaces')
    this.$store.dispatch('jobCategories/fetchJobCategories')
    this.$store.dispatch('workplaces/fetchWorkplaces')
    this.$store.dispatch('customWorkplaces/fetchWorkplaces')
    this.$store.dispatch('hospitals/fetchHospitals')
    this.$store.dispatch('workplaceSituations/fetchWorkplaceSituations')
    this.$store.dispatch('hospitals/fetchHospitals')
    if (this.$route.params.id) {
      this.$store
        .dispatch('myJobs/fetchJobById', this.$route.params.id)
        .then(() => {
          this.job = this.$store.getters['myJobs/selectedJob']
          this.job.job_categories = this.job.job_categories.map(el => el.id)
          this.job.specialties = this.job.specialties.map(el => el.id)
          this.job.county_id = this.job.county.id.toString()
          if (this.job.hiring_date) this.job.hiring_date = moment(this.job.hiring_date).format('YYYY-MM-DD')
          else this.hiring_date = false
          this.job.application_deadline = moment(this.job.application_deadline).format('YYYY-MM-DD')
          this.employer.workplace_situation_group = this.job.workplace_situation_group
          this.employer.workplace_situation_entity_id = this.job.workplace_situation_entity_id
          this.employer.workplace_situation = this.job.workplace_situation
          this.employer.workplace_situation_type = this.job.workplace_situation_type
        })
    } else this.employer.workplace_situation_group = 'general_practice'
  },
  methods: {
    selectCounty(countyId) {
      this.job.county_id = countyId
    },
    submitAction() {
      if (this.$route.params.id) {
        this.updateJob()
      } else this.createJob()
    },
    async createWorkplace(workplace) {
      const newWorkplace = await this.$store.dispatch(
        'customWorkplaces/createWorkplace',
        { name: workplace },
      )
      this.$store.dispatch('customWorkplaces/fetchWorkplaces')
      return newWorkplace.id
    },
    createJob() {
      this.$refs.simpleRules.validate().then(async success => {
        // Check for adding a new custom workplace
        if (this.employer.workplace_situation_group === 'other') {
          // Check for existing workplace
          const isDublicate = this.customWorkplaces.find(workplace => workplace.name === this.employer.workplace_situation_entity_id)
          if (isDublicate) {
            // Workplace allready exists set id to employer object
            this.employer.workplace_situation_entity_id = isDublicate.id
          } else {
            // Workplace are new, create it and set id to employer object
            this.employer.workplace_situation_entity_id = await this.createWorkplace(this.employer.workplace_situation_entity_id)
          }
        }
        const job = { ...this.job, ...this.employer }
        if (success) {
          this.$store.dispatch('myJobs/createJob', job).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Job created!'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/jobs')
            }
          })
        }
      })
    },
    updateJob() {
      this.$refs.simpleRules.validate().then(async success => {
        // Check for adding a new custom workplace
        if (this.employer.workplace_situation_group === 'other') {
          // Check for existing workplace
          const isDublicate = this.customWorkplaces.find(workplace => workplace.name === this.employer.workplace_situation_entity_id)
          if (isDublicate) {
            // Workplace allready exists set id to employer object
            this.employer.workplace_situation_entity_id = isDublicate.id
          } else {
            // Workplace are new, create it and set id to employer object
            this.employer.workplace_situation_entity_id = await this.createWorkplace(this.employer.workplace_situation_entity_id)
          }
        }
        const job = { ...this.job, ...this.employer }
        if (success) {
          this.$store.dispatch('myJobs/updateJob', { job, id: this.$route.params.id }).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('Job updated!'),
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push('/my_jobs')
            }
          })
        }
      })
    },
    closeCreationForm() {
      this.$router.go(-1)
    },
  },
}
</script>
