var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Available position"))+" ")]),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"w-100 px-1 py-2 create-form-body m-0 d-flex"},[_c('div',{staticClass:"col-lg-8 px-0"},[_c('div',{staticClass:"d-md-flex align-items-center mb-1"},[_c('h5',{staticClass:"font-weight-bolder w-25 mb-md-0"},[_vm._v(" "+_vm._s(_vm.$t("Job type"))+" ")]),_c('b-form-group',{staticClass:"mb-0 w-75"},[_c('validation-provider',{attrs:{"name":_vm.$t('Job type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.job_types,"state":errors.length > 0 ? false : null},model:{value:(_vm.job.type),callback:function ($$v) {_vm.$set(_vm.job, "type", $$v)},expression:"job.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"d-md-flex align-items-center mb-1"},[_c('h5',{staticClass:"font-weight-bolder w-25 mb-md-0"},[_vm._v(" "+_vm._s(_vm.$t("Category"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Category'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox-group',{attrs:{"options":_vm.jobCategories,"value-field":"id","text-field":"name","state":errors.length > 0 ? false : null},model:{value:(_vm.job.job_categories),callback:function ($$v) {_vm.$set(_vm.job, "job_categories", $$v)},expression:"job.job_categories"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Position type'),"label-for":"positionType"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Position type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"positionType","label":"text","options":_vm.rates,"reduce":function (rate) { return rate.value; },"state":errors.length > 0 ? false : null},model:{value:(_vm.job.rate),callback:function ($$v) {_vm.$set(_vm.job, "rate", $$v)},expression:"job.rate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-8 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Specialty'),"label-for":"specialty"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Specialty'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"id":"specialty","label":"name","multiple":"","reduce":function (specialty) { return specialty.id; },"options":_vm.specialties,"state":errors.length > 0 ? false : null},model:{value:(_vm.job.specialties),callback:function ($$v) {_vm.$set(_vm.job, "specialties", $$v)},expression:"job.specialties"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),_c('b-form-group',{attrs:{"label":_vm.$t('Job title')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Job title'),"rules":"required|max:80"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Place some text there...')},model:{value:(_vm.job.title),callback:function ($$v) {_vm.$set(_vm.job, "title", $$v)},expression:"job.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Description'),"label-for":"tinymce-editor"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Description'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('TinyMCE',{attrs:{"id":"tinymce-editor","placeholder":_vm.$t('Place some text there...')},model:{value:(_vm.job.description),callback:function ($$v) {_vm.$set(_vm.job, "description", $$v)},expression:"job.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"d-md-flex align-items-center mb-1"},[_c('h5',{staticClass:"font-weight-bolder w-25 mb-md-0"},[_vm._v(" "+_vm._s(_vm.$t("Hiring date"))+" ")]),_c('b-form-group',{staticClass:"mb-0"},[_c('b-form-radio-group',{attrs:{"options":_vm.hiring_dates},model:{value:(_vm.hiring_date),callback:function ($$v) {_vm.hiring_date=$$v},expression:"hiring_date"}})],1)],1),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Application deadline'),"label-for":"applicationDeadline"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Application deadline'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light",attrs:{"id":"applicationDeadline","nav-button-variant":"muted","placeholder":"YYYY/MM/DD","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"date-format-options":{
                    year: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                  },"state":errors.length > 0 ? false : null},model:{value:(_vm.job.application_deadline),callback:function ($$v) {_vm.$set(_vm.job, "application_deadline", $$v)},expression:"job.application_deadline"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.hiring_date)?_c('div',{staticClass:"col-md-4 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Select hiring date'),"label-for":"selectHiringDate"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Select hiring date'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"bg-light",attrs:{"id":"selectHiringDate","nav-button-variant":"muted","placeholder":"YYYY/MM/DD","locale":_vm.$i18n.locale === 'en' ? 'en-UK' : 'nb-NO',"date-format-options":{
                    year: 'numeric',
                    day: 'numeric',
                    month: 'numeric',
                  },"state":errors.length > 0 ? false : null},model:{value:(_vm.job.hiring_date),callback:function ($$v) {_vm.$set(_vm.job, "hiring_date", $$v)},expression:"job.hiring_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,991849847)})],1)],1):_vm._e()]),(_vm.employer.workplace_situation_group)?_c('h5',{staticClass:"font-weight-bolder mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Employer"))+" ")]):_vm._e(),(_vm.employer.workplace_situation_group)?_c('ChooseWorkplaceForm',{staticClass:"mb-2",attrs:{"value":_vm.employer,"have-initial-data":_vm.$route.params.id ? true : false,"only-hospital":true},on:{"changeCounty":_vm.selectCounty}}):_vm._e(),_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Work address"))+" ")]),_c('b-form-group',{attrs:{"label":_vm.$t('Address line'),"label-for":"addressLine1"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Address line'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"addressLine1","placeholder":_vm.$t('Address line'),"state":errors.length > 0 ? false : null},model:{value:(_vm.job.work_address_1),callback:function ($$v) {_vm.$set(_vm.job, "work_address_1", $$v)},expression:"job.work_address_1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"row",class:_vm.employer.workplace_situation_group === 'general_practice'? 'mb-2' : ''},[_c('div',{staticClass:"col-md-6 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Postal code'),"label-for":"postalCode"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Postal code'),"rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control bg-light",attrs:{"raw":false,"options":_vm.options.postal_code,"placeholder":_vm.$t('Postal code'),"state":errors.length > 0 ? false : null},model:{value:(_vm.job.postal_code),callback:function ($$v) {_vm.$set(_vm.job, "postal_code", $$v)},expression:"job.postal_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-md-6 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('Area'),"label-for":"Area"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"Area","placeholder":_vm.$t('Area')},model:{value:(_vm.job.work_address_2),callback:function ($$v) {_vm.$set(_vm.job, "work_address_2", $$v)},expression:"job.work_address_2"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)]),(_vm.employer.workplace_situation_group !== 'general_practice')?_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6 px-md-1 pl-1"},[_c('b-form-group',{attrs:{"label":_vm.$t('County'),"label-for":"city"}},[_c('validation-provider',{attrs:{"name":_vm.$t('County'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{staticClass:"bg-light",attrs:{"options":_vm.counties,"label":"name","reduce":function (county) { return county.id.toString(); },"placeholder":_vm.$t('County'),"state":errors.length > 0 ? false : null},model:{value:(_vm.job.county_id),callback:function ($$v) {_vm.$set(_vm.job, "county_id", $$v)},expression:"job.county_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3719781413)})],1)],1)]):_vm._e(),_c('h5',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("Contacts"))+" ")]),_c('b-form-group',{attrs:{"label":_vm.$t('Contact person'),"label-for":"contactPerson"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Contact person'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"contactPerson","placeholder":_vm.$t('Contact person')},model:{value:(_vm.job.contact_person),callback:function ($$v) {_vm.$set(_vm.job, "contact_person", $$v)},expression:"job.contact_person"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Phone'),"label-for":"contactPhoneNumber"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Phone'),"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control bg-light",attrs:{"raw":false,"options":_vm.options.phone,"placeholder":_vm.$t('Phone'),"state":errors.length > 0 ? false : null},model:{value:(_vm.job.contact_phone_number),callback:function ($$v) {_vm.$set(_vm.job, "contact_phone_number", $$v)},expression:"job.contact_phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Link to recruiting system'),"label-for":"linkToRecruitingSystem"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Link to recruiting system'),"rules":"url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light",attrs:{"id":"linkToRecruitingSystem","placeholder":"https://","state":errors.length > 0 ? false : null},model:{value:(_vm.job.recruiting_system_link),callback:function ($$v) {_vm.$set(_vm.job, "recruiting_system_link", $$v)},expression:"job.recruiting_system_link"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!this.$route.params.id && _vm.isABussinesProfile)?_c('b-form-checkbox',{staticClass:"mt-1",model:{value:(_vm.job.generate_post),callback:function ($$v) {_vm.$set(_vm.job, "generate_post", $$v)},expression:"job.generate_post"}},[_vm._v(" "+_vm._s(_vm.$t('Generate the post to the feed'))+" ")]):_vm._e()],1),_c('div',{staticClass:"col-lg-4 pl-1 text-right nopadding"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.submitAction}},[_vm._v(" "+_vm._s(_vm.$t(this.$route.params.id ? 'Save changes' : 'Publish'))+" ")]),_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.closeCreationForm}},[_vm._v(" "+_vm._s(_vm.$t(this.$route.params.id ? 'Close vacancy' : 'Cancel'))+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }